exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-privacy-terms-js": () => import("./../../../src/pages/privacy-terms.js" /* webpackChunkName: "component---src-pages-privacy-terms-js" */),
  "component---src-templates-content-js": () => import("./../../../src/templates/content.js" /* webpackChunkName: "component---src-templates-content-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-policy-js": () => import("./../../../src/templates/policy.js" /* webpackChunkName: "component---src-templates-policy-js" */),
  "component---src-templates-posts-dato-cms-post-slug-js": () => import("./../../../src/templates/posts/{DatoCmsPost.slug}.js" /* webpackChunkName: "component---src-templates-posts-dato-cms-post-slug-js" */)
}

